import React, {FC} from 'react';
import {css} from '@emotion/core';
import {Link, Flex} from 'theme-ui';

const Footer: FC = () => (
    <Flex
        css={css`
            flex-direction: row;
            justify-content: space-around;
            width: 80%;
        `}
    >
        <Link href="#!" variant="footer">
            Terms of Service{' '}
        </Link>
        <Link href="#!" variant="footer">
            Privacy Policy
        </Link>
        <Link href="#!" variant="footer">
            Contact
        </Link>
        <Link href="#!" variant="footer">
            Uninstall
        </Link>
    </Flex>
);

export default Footer;
