/** @jsx jsx */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import React, {FC, useEffect} from 'react';
import {Flex, jsx, useColorMode, Text} from 'theme-ui';
import {css} from '@emotion/core';
import SearchBar from '@s1/marketing-extension-theme/src/components/SearchBar';
import QuoteBlock from '../components/QuoteBlock';
import RecircContainer from '../components/Recirc';
import Search from '../../../assets/svg/Search.svg';
import Dogpile from '../../../assets/svg/dogpile.svg';

type TimeRange = {
    start: number;
    end: number;
    mode: 'morning' | 'afternoon' | 'evening';
};

const Fill = () => (
    <Flex
        css={css`
            flex: 1 1 auto;
        `}
    />
);

const Index: FC = () => {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const [_, setColorMode] = useColorMode();
    const timeColors: TimeRange[] = [
        {start: 0, end: 5, mode: 'evening'},
        {start: 5, end: 12, mode: 'morning'},
        {start: 12, end: 17, mode: 'afternoon'},
        {start: 17, end: 24, mode: 'evening'}
    ];

    useEffect(() => {
        const getColorModeFromTime = (hour: number) => {
            const foundRange = timeColors.find((timeRange) => hour >= timeRange.start && hour < timeRange.end);
            return foundRange?.mode;
        };
        const currentHour = new Date(Date.now()).getHours();
        const mode = getColorModeFromTime(currentHour);
        if (mode) {
            setColorMode(mode);
        }
    }, [setColorMode, timeColors]);

    return (
        <Flex
            css={css`
                width: 100%;
                align-items: center;
                flex-direction: column;
                height: 100%;
                flex: 1;
            `}
        >
            <Fill />
            <QuoteBlock />
            <SearchBar
                placeholder="Search the Web"
                icon={
                    <Search
                        css={css`
                            margin-right: 10px;
                        `}
                    />
                }
                searchStyle={css`
                    width: 521px;
                    border-radius: 41px;
                    background-color: #fff;
                    border: none;
                    box-shadow: unset;
                    padding-left: 20px;
                    *::placeholder {
                        color: #000;
                        font-size: 16px;
                        font-family: 'Montserrat';
                        font-weight: regular;
                    }
                `}
                onSubmit={(query) => {
                    const dogpileSearch = `https://www.dogpile.com/serp?q=${query}`;
                    window.location.href = dogpileSearch;
                }}
            />
            <Flex
                css={css`
                    flex-direction: row;
                    align-items: center;
                    margin: 20px;
                `}
            >
                <Text variant="labelLight">Powered by</Text>
                <Dogpile
                    css={`
                        margin-top: 3px;
                        margin-left: 5px;
                    `}
                />
            </Flex>
            <Fill />
            <RecircContainer
                recircProps={[
                    {
                        title: 'Is it Safe to Swim During the Coronavirus Outbreak?',
                        url: 'howStuffWorks.com'
                    },
                    {
                        title: '5 Things to Know Before you Shoot Video of a Possible Crime',
                        url: 'HowStuffWorks.com'
                    },
                    {
                        title: "We're Getting Closer to the Quantum Internet, But What is it?",
                        url: 'HowStuffWorks.com'
                    }
                ]}
            />
        </Flex>
    );
};

export default Index;
