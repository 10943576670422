import {useThemeUI} from 'theme-ui';

import React, {FC, useState, ChangeEvent, ReactElement} from 'react';
import {css} from '@emotion/core';

type SearchBarProps = {
    placeholder: string;
    onSubmit?: (query: string) => void;
    searchStyle?: ReturnType<typeof css>;
    icon?: ReactElement;
    inputStyle?: ReturnType<typeof css>;
};

const SearchBarResetCSS = css`
    input {
        &:focus {
            outline: none;
        }
    }
    button {
        border: 0;
        outline: 0;
        cursor: pointer;
        margin: 0;
    }
`;

const SearchBar: FC<SearchBarProps> = ({
    placeholder,
    searchStyle,
    inputStyle,
    onSubmit,
    icon = <span>Search</span>
}) => {
    const [query, setQuery] = useState('');

    const {theme} = useThemeUI();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    return (
        <form
            css={css`
                ${SearchBarResetCSS};
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                height: 50px;
                border-radius: 3px;
                overflow: hidden;
                box-shadow: 0px 0px 2px 2px #ccc;
                ${searchStyle};
            `}
            onSubmit={(e) => {
                e.preventDefault();
                if (onSubmit) {
                    onSubmit(query);
                }
            }}
        >
            <input
                placeholder={placeholder}
                type="input"
                value={query}
                onChange={handleChange}
                css={css`
                    margin-left: 10px;
                    border: none;
                    box-shadow: none;
                    width: 100%;
                    ${inputStyle};
                `}
            />
            <button
                type="submit"
                css={css`
                    background-color: ${theme.colors?.primary};
                    color: #fff;
                    padding: 0 10px;
                    align-self: 'flex-end';
                `}
            >
                {icon}
            </button>
        </form>
    );
};

export default SearchBar;
