import React, {FC} from 'react';
import {Flex, Text} from 'theme-ui';
import {css} from '@emotion/core';

type RecircProps = {
    title: string;
    url: string;
};

const Expand = () => (
    <Flex
        css={css`
            flex: 1;
        `}
    />
);

const Recirc: FC<RecircProps> = ({title = '', url = ''}) => (
    <Flex
        css={css`
            border-radius: 10px;
            border: solid 3px rgba(255, 255, 255, 0.25);
            padding: 15px 15px 10px 15px;
            margin: 0 15px;
            min-width: 250px;
            max-height: 120px;
            height: 120px;
            flex-direction: column;
        `}
    >
        <Text
            variant="label"
            css={css`
                font-size: 16px;
                line-height: 1.4;
            `}
        >
            {title}
        </Text>
        <Expand />
        <Text variant="labelLight">From {url}</Text>
    </Flex>
);

type RecircContainerProps = {
    recircProps: RecircProps[];
};

const RecircContainer: FC<RecircContainerProps> = ({recircProps = []}) => (
    <Flex
        css={css`
            max-width: 843px;
            flex-direction: column;
            margin-bottom: 20px;
        `}
    >
        <Text
            variant="labelLight"
            css={css`
                margin: 15px;
            `}
        >
            Recommended Content
        </Text>
        <Flex
            css={css`
                flex-direction: row;
            `}
        >
            {recircProps.map(({title = '', description = '', url = ''}) => (
                <Recirc title={title} description={description} url={url} />
            ))}
        </Flex>
    </Flex>
);

export default RecircContainer;
