import React, {FC} from 'react';
import IndexRoot from '../components/Index';
import DashboardLayout from '../templates/DashboardLayout';

const Index: FC = () => {
    return (
        <DashboardLayout>
            <IndexRoot />
        </DashboardLayout>
    );
};

export default Index;
