import React, {FC, useState} from 'react';
import {Flex, Text} from 'theme-ui';
import {css} from '@emotion/core';

const Clock: FC = () => {
    const [currentTime, setCurrentTime] = useState<Date>(new Date());

    setInterval(() => {
        setCurrentTime(new Date());
    }, 500);
    return (
        <Flex
            css={css`
                align-items: flex-end;
                flex-direction: column;
                font-size: 24px;
                line-height: 32px;
            `}
        >
            <Text variant="label">{currentTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}</Text>
            <Text variant="label">
                {currentTime.toLocaleDateString([], {weekday: 'long', month: 'short', day: 'numeric'})}
            </Text>
        </Flex>
    );
};

export default Clock;
