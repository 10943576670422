import React, {FC} from 'react';
import {Flex} from 'theme-ui';
import {css} from '@emotion/core';
import Clock from '../components/Clock';
import Logo from '../../../assets/svg/Logo.svg';

const Expand = () => (
    <Flex
        css={css`
            flex: 1;
        `}
    />
);
const Header: FC = () => (
    <Flex
        css={css`
            width: 100%;
            flex-directon: row;
        `}
    >
        <Logo />
        <Expand />
        <Clock />
    </Flex>
);

export default Header;
