/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {PropsWithChildren, FC} from 'react';
import {jsx} from 'theme-ui';
import Layout from './Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';

type DashboardProps = {};

const DashboardLayout: FC<PropsWithChildren<DashboardProps>> = ({children}) => {
    return (
        <Layout>
            <header
                sx={{
                    alignItems: 'center',
                    width: '100%',
                    maxhWidth: '100%',
                    variant: 'layout.header'
                }}
            >
                <Header />
            </header>
            <main
                sx={{
                    height: '100%',
                    flex: '1 1 auto',
                    variant: 'layout.main'
                }}
            >
                {children}
            </main>
            <footer
                sx={{
                    width: '100%',
                    maxwidth: '100%',
                    alignItems: 'center',
                    variant: 'layout.footer'
                }}
            >
                <Footer />
            </footer>
        </Layout>
    );
};

export default DashboardLayout;
