/** @jsx jsx */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {FC, PropsWithChildren} from 'react';
import {jsx} from 'theme-ui';
import {Global} from '@emotion/core';
import SEO from '../components/SEO';

type LayoutProps = {};

const Layout: FC<PropsWithChildren<LayoutProps>> = ({children}) => {
    return (
        <div
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                margin: 0,
                padding: 0,
                variant: 'layout.root'
            }}
        >
            <Global
                styles={() => ({
                    body: {margin: 0, padding: 0}
                })}
            />
            <SEO />
            {children}
        </div>
    );
};

export default Layout;
