import React, {FC, useState, useEffect} from 'react';
import {Flex, Text} from 'theme-ui';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';

type QuoteProps = {};

type Quote = {
    author: string;
    quote: string;
};

const QuoteBlock: FC<QuoteProps> = () => {
    const [randomQuote, setRandomQuote] = useState<Quote>({
        author: '',
        quote: ''
    });

    const {
        allQuotesJson: {edges: quotes}
    } = useStaticQuery(graphql`
        {
            allQuotesJson {
                edges {
                    node {
                        author
                        quote
                    }
                }
            }
        }
    `);
    const getRandomQuote = () => {
        const choice = Math.floor(Math.random() * quotes.length);
        setRandomQuote(quotes[choice].node);
    };

    useEffect(getRandomQuote, []);
    return (
        <Flex
            css={css`
                flex-direction: column;
                max-width: 800px;
            `}
        >
            <Text
                css={css`
                    text-align: center;
                `}
                variant="quote"
            >
                “{randomQuote.quote}”
            </Text>
            <Text
                css={css`
                    text-align: center;
                    margin: 30px;
                `}
                variant="label"
            >
                - {randomQuote.author}
            </Text>
        </Flex>
    );
};

export default QuoteBlock;
